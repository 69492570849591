import React, { useState } from 'react';
import { fetchOrderDetails } from  './Hygraph'
import './Home.css'
import logo from './logo.png'

const OrderTracking = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [orderDetails, setOrderDetails] = useState(null);
  const [error, setError] = useState(null);

  const handleTrackingSubmit = async (e) => {
    e.preventDefault();
    setError(null);


  if (!trackingNumber) {
    setError("Please enter a valid tracking number.");
    return;
  }


    const data = await fetchOrderDetails(trackingNumber);

    if (data) {
      setOrderDetails(data);
    } else {
      setError("No order details found. Please check the tracking number.");
    }
  };


// Helper function to format order status

const formatStatus = (status) =>
  status.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase());

// Helper function to format time 
const formatDateToPST = (isoString) => {
  const date = new Date(isoString);
  return date.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "numeric",
    minute: "2-digit",
    hour12: true
  });
};




  return (
    <div className='page-container'>
    
      <div className='logo-container'>
      <img src={logo} alt='Zipbox' className='logo'/> 
      </div>

      <div className='fullform'>
      
         <h1>Track Your Order</h1>
          
          <div className='form'>

          <form onSubmit={handleTrackingSubmit}>

          <input className='inputform'
          type="text"
          placeholder="Enter Tracking Number"
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
          />
          <button type="submit" className='button1'>Track Order</button>
          </form>
     
          </div>


          {error && <p style={{ color: 'red' }}>{error}</p>}

             {orderDetails && (
              <div>
          
             <div className='tracking-number'>
             <h2>Tracking Number:</h2>
             <p>{orderDetails.trackingNumber}</p>
             </div>
         
               <div className='order-details'>
               <h2>Order Details :</h2>
              </div>
              {orderDetails.trackingPoint.map((point, index) => (
          
              <div key={`${point.trackingNumber}-${index}`} className='tracking-points' >
              
                <p><strong>Delivery Location:</strong> {point.deliveryLocation}</p>
                <p><strong>Arrival Date:</strong> {point.arrivalDate}</p>
                {/* Conditionally render the "Delivery Window" only if no proof of delivery image is present */}
                {!point.customerImage?.url && (
              <p><strong>Delivery Window:</strong> {point.arrivalTime}</p>
                 )}
                <p><strong>Status:</strong> {formatStatus(point.orderStatus)}</p>
                <p><strong className='time-stamp'>Tracking Update:</strong> {formatDateToPST(point.trackingUpdate)}</p>
              
 
   {/* Conditional rendering for proof of delivery image */}
   {point.customerImage?.url && (
                  <div className="proof-of-delivery">
                    <p><strong>Proof of Delivery:</strong></p>
                    <img
                      src={point.customerImage.url}
                      alt="Proof of Delivery"
                      className="proof-image"
                      style={{ maxWidth: "400px", marginTop: "10px" }}
                    />
                  </div>
                )}

               


            </div>
          ))}
        
  

        </div>
      )}
 
    </div>

    <p className='p-tag'> Contact sales@usezipbox.com for tracking issues </p>
    
    </div>
  );
};

export default OrderTracking;








