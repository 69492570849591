
import Homepage from './Homepage';


 
const App = () => {
   return (
      <>
         <Homepage  />
         
      </>
   );
};
 
export default App; 